@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

:root {
  --text: #453750;
  --form: #e6e1e7;
  --button: #B28AEC;
}

* {
    font-family: 'Quicksand', sans-serif;
    color: var(--text);
    font-size: 20px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/background.png");
  background-size: cover;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav a {
  text-decoration: none;
  color: var(--text);
  margin: 0px 35px;
  padding: 10px;
}

nav a:hover{
  border-bottom: 1px solid var(--text);
  color: var(--text);
}

input, textarea {
  background-color: var(--form);
  color: var(--text);
}

button {
  border: 1px solid var(--form);
  background-color: var(--button);
  max-width: 250px;
}

.email, .email:hover {
  color: var(--text);
}

.textLayer, .annotationLayer {
  display: none;
}


@media only screen and (max-width: 600px) {
  nav a {
    margin: 0px 10px;
  }
}